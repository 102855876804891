import React, { useState, useCallback, useEffect, useReducer } from 'react';
import Box from '@mui/material/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
import { getRoles, getPrivileges, getPrivilegesByRole, postRoles, patchRolePrivileges } from '../../utils/end-points.js';
import useHttp from '../../utils/http';
import BackdropProgress from '../../BackdropProgress.js';
import CustomSnackbar from '../ui/Snackbars.js';
import "../../css/RoleSettings.scss"
import ConfirmationDialog from '../dialog/ConfirmationDialog.js';

const filter = createFilterOptions();

// const useStyles = makeStyles((theme) => ({
//     root: {
//         width: '10%',
//         minWidth: 100,
//     },
//     page: {
//         padding: 20,
//     },
//     createNewButton: {
//         paddingRight: 8,
//         paddingLeft: 8,
//     },
//     paper: {
//         backgroundColor: theme.palette.background.paper,
//         boxShadow: theme.shadows[5],
//         padding: theme.spacing(2, 4, 3),
//         minWidth: 1000,
//     },

//     formControl: {
//         margin: theme.spacing(1),
//         minWidth: 500,
//     },
//     btnControl: {
//         margin: theme.spacing(1),
//         minWidth: 100,
//     },
// }));

const RoleSettings = () => {

    const { handleRequest } = useHttp();
    const [roles, setRoles] = useState([]);
    const [privileges, setPrivileges] = useState([]);
    const [selectedRole, setSelectedRole] = useState("");
    const [isLoading, setLoading] = useState(true);
    const [snackbarData, setSnackbarData] = useState({ open: false, message: "", severity: "", onClose: null })
    const [confirmationDialogData, setConfirmationDialogData] = useState({ open: false, title: "", content: "", acceptLabel: "", declineLabel: "", acceptAlert: null, declineAlert: null })

    useEffect(() => {
        fetchData()
    }, []);

    const fetchData = useCallback(async (showSuccessSnack) => {
        setLoading(true)
        const promises = []
        promises.push(new Promise(function (resolve, reject) {
            handleRequest(getRoles, 'GET', null, (roles) => {
                setRoles(roles);
                if (roles.length > 0) {
                    setSelectedRole(roles[0]);
                }
                if (showSuccessSnack) {
                    setSnackbarData({ message: "Success", open: true, severity: "success", onClose: onCloseSnackbar })
                }
                resolve()
            }, (error) => {
                setSnackbarData({ message: error, open: true, severity: "error", onClose: onCloseSnackbar })
                reject()
            })
        }))

        promises.push(new Promise(function (resolve, reject) {
            handleRequest(getPrivileges, 'GET', null, (data) => {
                let checkboxes = [];

                data.forEach(privilege => {
                    checkboxes.push({ id: privilege.id, name: privilege.name, description: privilege.description, isChecked: false });
                })

                setPrivileges(checkboxes);
                resolve()
            }, (error) => {
                setSnackbarData({ message: error, open: true, severity: "error", onClose: onCloseSnackbar })
                reject()
            })
        }))

        Promise.all(promises)
            .then(() => {
                setLoading(false)
            })
            .catch((error) => {
                setSnackbarData({ message: "Failed", open: true, severity: "error", onClose: onCloseSnackbar })
                console.error(error)
            })

    }, []);

    const getUserPrivilegesByRole = useCallback(async (selectedRole) => {

        setLoading(true)
        const endPointUrl = getPrivilegesByRole;
        const params = `roleId=${selectedRole.id}`;
        const requestUrl = `${endPointUrl}?${params}`;
        handleRequest(requestUrl, 'GET', null, (privilegeIdList) => {
            setPrivileges(prevState => {
                let privilegesNewState = [...prevState];

                privilegesNewState.forEach(privilege => {
                    if (privilegeIdList.includes(privilege.id)) {
                        privilege.isChecked = true;
                    } else {
                        privilege.isChecked = false;
                    }
                });

                return privilegesNewState;
            });
        }, () => {
            setSnackbarData({ message: "Error while fetching privileges", open: true, severity: "error", onClose: onCloseSnackbar })
        }, setLoading);
    }, []);

    const addNewRole = (newRoleName) => {
        if (roles.filter(role => role.name === newRoleName).length > 0) {
            setSnackbarData({ message: "Role already Exists!", open: true, severity: "error", onClose: onCloseSnackbar })
            return
        }
        setConfirmationDialogData({
            open: true,
            title: "Add New Role",
            content: "Are you sure you want to add the new user role, " + newRoleName + "?",
            acceptLabel: "Yes",
            declineLabel: "No",
            acceptAlert: () => handleAcceptAddNewRole(newRoleName),
            declineAlert: handleDeclineAlert
        })

    };


    const handleAcceptAddNewRole = useCallback(async (newRoleName) => {
        setConfirmationDialogData(data => ({ ...data, open: false }))
        setLoading(true)
        const body = {
            name: newRoleName
        }
        handleRequest(postRoles, 'POST', body, (data) => {
            if (data) {
                fetchData(true)
            }
            else {
                setSnackbarData({ message: "Error while adding role", open: true, severity: "error", onClose: onCloseSnackbar })
            }
        }, (error) => {
            setSnackbarData({ message: error, open: true, severity: "error", onClose: onCloseSnackbar })
        }, setLoading)
    }, []);

    useEffect(() => {
        if (selectedRole) {
            getUserPrivilegesByRole(selectedRole);
        }
    }, [selectedRole]);


    const onSelectedRoleChange = (event, newValue) => {
        if (typeof newValue === 'string') {
            setSelectedRole({
                name: newValue,
            });
        } else if (newValue && newValue.inputValue) {
            addNewRole(newValue.inputValue);
        } else {
            setSelectedRole(newValue);
        }
    }

    const onClickSave = () => {

        setConfirmationDialogData({
            open: true,
            title: "Update Privileges",
            content: "Are you sure you want to update privileges?",
            acceptLabel: "Yes",
            declineLabel: "No",
            acceptAlert: () => handleAcceptUpdatePrivileges(),
            declineAlert: handleDeclineAlert
        })
    }

    const handleAcceptUpdatePrivileges = () => {
        setConfirmationDialogData(data => ({ ...data, open: false }))
        setLoading(true)
        let grantedPrivilegeIds =[]
        privileges.map((privilege)=>{
            if(privilege.isChecked){
                grantedPrivilegeIds.push(privilege.id)
            }
        })
        const body = {
            roleId: selectedRole.id,
            privilegeIds: grantedPrivilegeIds
        }
        console.log(body)
        handleRequest(patchRolePrivileges, 'PATCH', body, (data) => {
            if (data) {
                setSnackbarData({ message: "Success", open: true, severity: "success", onClose: onCloseSnackbar })
            }
            else {
                setSnackbarData({ message: "Error while adding role", open: true, severity: "error", onClose: onCloseSnackbar })
            }
        }, (error) => {
            setSnackbarData({ message: error, open: true, severity: "error", onClose: onCloseSnackbar })
        }, setLoading)
    };


    const onClickReset = () => {
        setConfirmationDialogData({
            open: true,
            title: "Reset User Privileges",
            content: "Are you sure you want to reset the user privileges?",
            acceptLabel: "Yes",
            declineLabel: "No",
            acceptAlert: handleAcceptReset,
            declineAlert: handleDeclineAlert
        })
    }

    const handleAcceptReset = () => {
        setConfirmationDialogData(data => ({ ...data, open: false }))
        getUserPrivilegesByRole(selectedRole);
    };

    const handleChange = index => event => {
        let checkBoxesCurrent = [...privileges];
        checkBoxesCurrent[index].isChecked = event.target.checked;

        setPrivileges(checkBoxesCurrent);
    };

    const showPageInfo = () => {
        // let confirmDialogDetails = ROLE_SETTINGS.MESSAGES.SHOW_PAGE_INFO;

        // dispatchForm({
        //     type: ACTIONS.SHOW_PAGE_INFO,
        //     confirmDialogDetails: {
        //         open: true,
        //         id: confirmDialogDetails.id,
        //         title: confirmDialogDetails.title,
        //         message: confirmDialogDetails.message
        //     },
        // });
    }

    const handleDeclineAlert = () => {
        setConfirmationDialogData(data => ({ ...data, open: false }))
    }

    const onCloseSnackbar = () => {
        setSnackbarData({ ...snackbarData, open: false })
    }

    return (
        <div className="Role-settings">
            <BackdropProgress open={isLoading} />
            <CustomSnackbar snackbar={snackbarData} />
            <ConfirmationDialog confirmationDialogData={confirmationDialogData} />
            <div className='Page-title'>Roles Settings</div>
            <Paper className='Border-elevation Paper'>
                <Autocomplete
                    size="small"
                    variant="standard"
                    noOptionsText="No options"
                    value={selectedRole}
                    onChange={onSelectedRoleChange}
                    className="User-role-autocomplete"
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        // Suggest the creation of a new value
                        if (params.inputValue !== '') {
                            filtered.push({
                                inputValue: params.inputValue,
                                name: `Add "${params.inputValue}"`,
                            });
                        }

                        return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    disableClearable
                    options={roles}
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        // Regular option
                        return option.name;
                    }}
                    renderOption={(props, option) => (
                        <Box {...props} key={option.id}>{option.name}</Box>
                    )
                    }
                    style={{ width: 500 }}
                    renderInput={(params) => <TextField  {...params} label="User Role" />}
                />

                {privileges && privileges.map((privilege, index) => (
                    <div>

                        <FormControlLabel
                            control={<Checkbox checked={privilege.isChecked} onChange={handleChange(index)} key={privilege.id} name={privilege.id} color='secondary' />}
                            label={
                                <span>
                                    <span>{privilege.name}</span>
                                    <span style={{ color: 'grey' }}>{' (' + privilege.description + ')'}</span>
                                </span>
                            }
                            key={privilege.id}
                        />
                    </div>

                ))}

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    className="Button-grid">

                    <Button variant="contained" size="small" color="secondary" onClick={onClickSave} className="Save-button">
                        Save
                    </Button>
                    <Button variant="contained" size="small" color="primary" onClick={onClickReset} className="Reset-button">
                        Reset
                    </Button>
                </Grid>
            </Paper>

        </div>
    );
};

export default RoleSettings;