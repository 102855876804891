import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableHead from "@material-ui/core/TableHead";
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import wso2Theme from '../../theme';

export default function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, headCells } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead style={{ backgroundColor: wso2Theme.palette.primary.light }}>
            <TableRow >
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className='Head-label' >
                        {headCell.sortable ? <TableSortLabel className='Table-sort-label'
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}>
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel> : <div className='Table-unsortable-label'>{headCell.label}</div>}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}